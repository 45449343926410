#categories {
  .category-list-title {
    color: $color-meta;
  }
  .category-list {
    .category-list-item {
      .category-list-count {
        color: $color-meta;
      }
      .category-list-count:before {
        content: " (";
      }
      .category-list-count:after {
        content: ")";
      }
    }
  }
}